export const getClientTypeBadgeStyles = (type) => {
  const typeStyles = {
    paid: {
      className: "border-green-400 bg-green-100 text-green-700",
      label: "Paid",
    },
    partner: {
      className: "border-purple-400 bg-purple-100 text-purple-700",
      label: "Partner",
    },
    trial: {
      className: "border-blue-400 bg-blue-100 text-blue-700",
      label: "Trial",
    },
    demo: {
      className: "border-yellow-400 bg-yellow-100 text-yellow-700",
      label: "Demo",
    },
    internal: {
      className: "border-gray-400 bg-gray-100 text-gray-700",
      label: "Internal",
    },
  };

  return (
    typeStyles[type] || {
      className: "border-gray-300 bg-gray-100 text-gray-500",
      label: "Unknown",
    }
  );
};
