import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import React, { useEffect, useState, useCallback, useRef } from "react";
import NoData from "src/components/Shared/NoData/NoData";
import ClientSetupItem from "./ClientSetupItem";
import { apiRequest } from "src/async/apiUtils";

const ClientSetup = ({ details, statuses, setStatusData }) => {
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const stepInterval = useRef(null);

  const fetchClientStepData = useCallback(
    async (isFirstTime = false) => {
      if (!details?._id) return;

      if (isFirstTime) {
        setIsLoading(true);
        setStatusData((pre) => ({ ...pre, isUpdating: true }));
      }

      try {
        const { data } = await apiRequest("GET", `/clients/${details._id}/steps`);
        setSteps(data?.data?.steps || []);
        setStatusData((pre) => ({ ...pre, status: data?.data?.status || "" }));
      } catch (error) {
      } finally {
        setIsLoading(false);
        setStatusData((pre) => ({ ...pre, isUpdating: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details?._id],
  );

  useEffect(() => {
    fetchClientStepData(true);
  }, [fetchClientStepData]);

  useEffect(() => {
    const hasIncompleteSteps = steps.some((item) => item.status !== "completed");

    if (stepInterval.current) {
      clearInterval(stepInterval.current);
      stepInterval.current = null;
    }

    if (hasIncompleteSteps) {
      stepInterval.current = setInterval(() => fetchClientStepData(false), 5000);
    }

    return () => {
      if (stepInterval.current) {
        clearInterval(stepInterval.current);
        stepInterval.current = null;
      }
    };
  }, [steps, fetchClientStepData]);

  if (isLoading) {
    return (
      <div className="relative">
        <Preloader color="text-gray-400 fill-gray-700" />
      </div>
    );
  }

  return (
    <div className="relative">
      {steps.length > 0 ? (
        <div className="divide-y divide-gray-200">
          {steps.map((item) => (
            <ClientSetupItem
              key={item._id}
              item={item}
              details={details}
              statuses={statuses}
              refreshData={fetchClientStepData}
            />
          ))}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default ClientSetup;
