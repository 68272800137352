import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import "react-quill/dist/quill.snow.css";

import { logout } from "src/actions/auth";
import { getOpenSiteSettings, getSiteSettings } from "src/actions/site";
import ScrollToTop from "src/components/Shared/ScrollToTop";
import routes from "src/routes/routes";
// import { WebSocketProvider } from "./context/WebSocketContext";

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = ({ logout, ...props }) => {
  const { site, me } = props;

  const loadSettings = async () => {
    const ac = new AbortController();
    try {
      if (me?._id && localStorage.getItem("access_token")) {
        await props.getSiteSettings({ token: me?.access_token }, ac.signal);
      } else {
        await props.getOpenSiteSettings({}, ac.signal);
      }
    } catch (error) {
      // throw new Error(error.message);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, localStorage.getItem("access_token")]);

  useEffect(() => {
    if (Object.keys(site)?.length) {
      document.documentElement.style.setProperty("--highlightColor", site.highlight_color, "important");
      document.documentElement.style.setProperty("--headerColor", site.top_bar_color, "important");
      document.documentElement.style.setProperty("--headerTextColor", site.top_bar_text_color, "important");
      document.documentElement.style.setProperty("--leftNavColor", site.side_bar_color, "important");
      document.documentElement.style.setProperty("--leftNavActiveColor", site.side_bar_shade_color, "important");
      document.documentElement.style.setProperty("--leftNavTextColor", site.side_bar_text_color, "important");
      document.documentElement.style.setProperty("--leftNavActiveTextColor", site.left_nav_active_text_color, "important");
      document.title = site.site_name;
      if (site.image_favicon) {
        document.querySelector("link[rel*='icon']").href = `${site.image_favicon}?${Date.now()}`;
      } else {
        document.querySelector("link[rel*='icon']").href = "./assets/img/image_favicon-default.png";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  return (
    // <WebSocketProvider>
    <BrowserRouter>
      <div className="flex h-screen">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {routes}
      </div>
    </BrowserRouter>
    // </WebSocketProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getOpenSiteSettings, getSiteSettings, logout })(App);
