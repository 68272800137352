import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { classNames } from "src/helpers/classNames";

const Editor = ({ label, name, placeholder, value, onChange = () => {}, error = false, labelClassNames = "", inline = false, wrapperClass }) => {
  const [textValue, setTextValue] = useState("");

  const labelClasses = classNames(labelClassNames, "flex text-sm font-medium mb-1");

  const modules = {
    toolbar: [[{ font: [] }, { size: ["small", "medium", "large", "huge"] }], [{ list: "ordered" }, { list: "bullet" }], [{ align: [] }], ["bold", "italic", "underline", "strike"], [{ color: [] }, { background: [] }], [{ indent: "-1" }, { indent: "+1" }]],
  };

  const onEditorStateChange = (editorState) => {
    if (editorState === "<p><br></p>") {
      setTextValue(undefined);
      onChange({ target: { name: name, value: undefined } });
    } else {
      setTextValue(editorState);
      onChange({ target: { name: name, value: editorState } });
    }
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  return (
    <div className={classNames("min-h-[200px] w-full", inline ? "relative h-full space-y-2 overflow-hidden rounded-md border px-0 pt-2 focus-within:border-highlightColor" : "relative h-full", wrapperClass)}>
      <label
        htmlFor={name}
        className={inline ? classNames("m-0 flex pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3", labelClasses) : labelClasses}>
        {label} {error && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
      </label>
      <ReactQuill
        theme="snow"
        value={textValue}
        onChange={onEditorStateChange}
        placeholder={placeholder}
        modules={modules}
        style={{ border: "none" }}
        className="border-none"
      />
    </div>
  );
};

export default Editor;
