import moment from "moment";

export const previousDateInString = (date, format = "MM/DD/YYYY") => {
  const targetDate = moment.utc(date).startOf("day"); // Convert to UTC & start of the day
  const today = moment.utc().startOf("day"); // Ensure today is also at start of the day

  const daysDiff = today.diff(targetDate, "days");

  if (daysDiff === 0) return "Today";
  if (daysDiff === 1) return "Yesterday";
  if (daysDiff <= 6) return `${daysDiff} days ago`;
  if (daysDiff <= 13) return "1 week ago";
  if (daysDiff <= 20) return "2 weeks ago";
  if (daysDiff <= 27) return "3 weeks ago";
  if (daysDiff <= 59) return "1 month ago";
  if (daysDiff <= 89) return "2 months ago";

  return targetDate.format(format);
};
