import React from "react";
import UserProfile from "src/components/Users/UserProfile";
import { classNames } from "src/helpers/classNames";
import { getClientTypeBadgeStyles } from "src/helpers/clientHelper";
import { previousDateInString } from "src/helpers/dateHelper";

const ClientItems = ({ data = null }) => {
  const { className, label } = getClientTypeBadgeStyles(data?.client_type);

  return (
    <>
      <div className="h-10 w-10 overflow-hidden rounded-full">
        <UserProfile
          user={{ ...data, image: data?.company_image }}
          className="!bg-highlightColor !text-white"
        />
      </div>
      <div className="max-w-[240px] items-start overflow-hidden">
        <div className="w-full max-w-[150px] overflow-hidden truncate text-ellipsis whitespace-nowrap text-left text-base font-semibold text-gray-900">{data.company_name || data.name}</div>
        <div className="w-full truncate text-left text-sm text-gray-500">{data.domain}</div>
      </div>
      <div className="absolute right-2 top-2.5 flex gap-x-2">
        <div className="flex items-center justify-center gap-2">
          <time
            dateTime={data.created_at}
            className="flex h-6 items-center justify-center whitespace-nowrap rounded bg-gray-100 px-2 text-xs font-semibold text-gray-400 transition-all duration-200 group-hover:bg-white">
            {previousDateInString(data.created_at)}
          </time>
          {data?.client_type && <div className={classNames("flex h-6 items-center justify-center whitespace-nowrap rounded  px-2 text-xs font-semibold  transition-all duration-200 group-hover:bg-white", className)}>{label}</div>}
        </div>
      </div>
    </>
  );
};

export default ClientItems;
